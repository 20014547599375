/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import SinglePage from '../../components/singlePage'

class Contact extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    GET_FORM_PROPERTIES: PropTypes.func.isRequired,
    SEND_CONTACT_EMAIL: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
    formProperties: PropTypes.object,
    status: PropTypes.object,
  }

  static defaultProps = {
    dataSinglePage: [],
    formProperties: null,
    status: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      pageContact: 3066,
      name: '',
      email: '',
      tel: '',
      subject: '',
      message: '',
    }
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC, GET_FORM_PROPERTIES } = this.props
    GET_ARTICLE_ASYNC(3062)
    GET_FORM_PROPERTIES()
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 3066)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  sendEmail = (e) => {
    e.preventDefault()
    const { SEND_CONTACT_EMAIL } = this.props
    const {
      name,
      email,
      tel,
      subject,
      message,
    } = this.state
    const data = {
      'your-name': name,
      'your-email': email,
      'your-subject': subject,
      'your-message': message,
    }
    setTimeout(() => {
      SEND_CONTACT_EMAIL(data)
    }, 400)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  displayForm() {
    const { formProperties, status } = this.props
    const {
      properties: {
        form: {
          fields,
          content,
        },
      },
    } = formProperties
    const displayErrors = () => {
      if (status !== null) return <p className={status.status === 'validation_failed' ? 'error' : 'success'}>{status.message}</p>
      return null
    }
    return (
      <div className="contactForm">
        <form onSubmit={this.sendEmail}>
          {
            fields.flatMap((field, i) => (field.name !== '' ? (
              <div key={i} className={field.basetype}>
                <label>{content.split('</label>')[i].replace('<label>', '').replace(`[${field.type} ${field.name}]`, '')}</label>
                {
                  field.basetype === 'textarea' ? (
                    <textarea key={i} type={field.basetype} name={(field.name).replace('your-', '')} value={this.state[(field.name).replace('your-', '')]} onChange={this.handleChange} required={field.type.includes('*')} />
                  ) : (
                    <input key={i} type={field.basetype} name={(field.name).replace('your-', '')} value={this.state[(field.name).replace('your-', '')]} onChange={this.handleChange} required={field.type.includes('*')} />
                  )
                }
              </div>
            ) : false))
          }
          <button type="submit">Envoyer</button>
          <p>Si vous souhaitez nous joindre vous pouvez également nous appeler au 05 56 69 07 72</p>
          {displayErrors()}
        </form>
      </div>
    )
  }

  render() {
    const { formProperties } = this.props
    const { pageContact } = this.state
    return (
      <div id="Container" ref={this.element}>
        <div id="princing-part-title">
          <h1>Contactez nous</h1>
        </div>
        <SinglePage id={pageContact} noTitle />
        {formProperties !== null ? this.displayForm() : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
  formProperties: state.contact.formProperties,
  status: state.contact.status,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
  GET_FORM_PROPERTIES: () => dispatch({ type: 'GET_FORM_PROPERTIES' }),
  SEND_CONTACT_EMAIL: (bodyFormData) => dispatch({
    type: 'SEND_CONTACT_EMAIL', bodyFormData,
  }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
