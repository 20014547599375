import {
  GET_PREVIEW_POST,
  GET_PREVIEW_PAGE,
} from './actions'

const initialState = {
  previewPost: [],
  previewPage: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PREVIEW_POST:
      return {
        previewPost: action.previewPost,
      }
    case GET_PREVIEW_PAGE:
      return {
        previewPage: action.previewPage,
      }
    default:
      return state
  }
}
