import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import SinglePost from '../../components/singlePost'

class Post extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    match: {},
    dataSinglePage: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      idPage: null,
    }
  }

  componentDidMount() {
    const {
      match,
    } = this.props

    // document.title = 'MonEntrepriz - Article'

    this.setState({ 
      idPage: match.params.id,
    })
  }

  componentDidUpdate() {
    const {
      dataSinglePage,
      match,
    } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === match.params.id)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }
  
  render() {
    const {
      idPage,
    } = this.state
    
    const id = idPage
    return (
      <div id="Container">
        <div id="post-standard">
          {idPage !== null && <SinglePost id={id} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
})

export default connect(mapStateToProps)(Post)
