import axios from 'axios'
import config from '../../../config'

const getLastPosts = () => axios({
  method: 'get',
  baseURL: `${config.urlWp}posts?page=1`,
})

export default {
  getLastPosts,
}
