import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import MenuCategories from '../../components/menuCategories'
import LastPosts from '../../components/lastPosts'
import NewsLetter from '../../components/newsLetter'
import ArticlesList from '../../components/articlesList'

class PostsByCategorie extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    dataByPage: PropTypes.object,
    numberOfPages: PropTypes.number,
    GET_POSTS_BY_CATEGORY_ASYNC: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    SET_CURRENT_PAGE: PropTypes.func.isRequired,
    SET_CURRENT_PATHNAME: PropTypes.func.isRequired,
    pathnamePagination: PropTypes.string,
  }

  static defaultProps = {
    match: {},
    dataByPage: null,
    numberOfPages: null,
    currentPage: null,
    pathnamePagination: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      idCategorie: null,
      getContent: false,
      content: null,
    }
  }

  componentDidMount() {
    const {
      match,
      SET_CURRENT_PAGE,
      SET_CURRENT_PATHNAME,
      pathnamePagination,
    } = this.props

    document.title = 'MonEntrepriz - Articles par catégorie'

    if (pathnamePagination !== location.pathname) {
      SET_CURRENT_PAGE(1)
      SET_CURRENT_PATHNAME(location.pathname)
    }

    this.setState({ 
      idCategorie: match.params.id,
    })
  }

  componentDidUpdate() {
    const {
      // currentPage,
      idCategorie,
      getContent,
    } = this.state
    
    const {
      match,
      GET_POSTS_BY_CATEGORY_ASYNC,
      SET_CURRENT_PAGE,
      dataByPage,
      currentPage,
    } = this.props
    
    // this.styleCurrentPage()
    this.getCurrentCattegory()

    if (dataByPage) {
      this.setState({
        numberOfPages: dataByPage.numberOfPages,
      })
    }
    // New category
    if (idCategorie !== match.params.id) {
      SET_CURRENT_PAGE(1)
      this.setState({
        idCategorie: match.params.id,
        getContent: false,
      })
    }
    if (idCategorie === match.params.id && !getContent) {
      GET_POSTS_BY_CATEGORY_ASYNC(idCategorie, currentPage)
      this.setState({
        getContent: true,
      })
    }
    // DATA for page
    if (dataByPage.category === parseInt(idCategorie, 10)) {
      this.setState({
        content: dataByPage.data,
      })
    }

    // New page
    if (currentPage !== dataByPage.page) {
      GET_POSTS_BY_CATEGORY_ASYNC(idCategorie, currentPage)
    }
  }

  getCurrentCattegory() {
    const {
      match,
    } = this.props    
    const listCategories = document.querySelectorAll('#menu-categories ol li')

    _.map(listCategories, (elem) => {
      const idLi = elem.id
      elem.classList.remove('current-category')

      if (idLi === match.params.id) {
        elem.classList.add('current-category')
      }
    })
  }

  render() {
    const {
      content,
      numberOfPages,
    } = this.state
    
    return (
      <div id="Container">
        <MenuCategories />
        
        <div id="articles-blog">
          {content && <ArticlesList content={content} numberOfPages={numberOfPages} />}
          <div id="part-of-last-article">
            <LastPosts type="title" />
          </div>
        </div>

        <NewsLetter />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataByPage: state.postsByCategorie.dataByPage,
  dataMedia: state.home.dataMedia,
  currentPage: state.pagination.currentPage,
  pathnamePagination: state.pagination.pathnamePagination,
})

const mapDispatchToProps = (dispatch) => ({
  GET_POSTS_BY_CATEGORY_ASYNC: (category, page) => dispatch({ type: 'GET_POSTS_BY_CATEGORY_ASYNC', category, page }),
  SET_CURRENT_PAGE: (currentPage) => dispatch({ type: 'SET_CURRENT_PAGE', currentPage }),
  SET_CURRENT_PATHNAME: (pathnamePagination) => dispatch({ type: 'SET_CURRENT_PATHNAME', pathnamePagination }),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostsByCategorie)
