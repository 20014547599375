import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'

class Preview extends PureComponent {
  static propTypes = {
    previewPost: PropTypes.array,
    previewPage: PropTypes.array,
    dataMedia: PropTypes.array,
    GET_DRAFT_POST: PropTypes.func.isRequired,
    GET_DRAFT_PAGE: PropTypes.func.isRequired,
    GET_PREVIEW: PropTypes.func.isRequired,
    GET_MEDIA_ASYNC: PropTypes.func.isRequired,
  }

  static defaultProps = {
    previewPost: [],
    previewPage: [],
    dataMedia: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      title: null,
      content: null,
      featuredMediaCurrent: null,
      linkImg: null,
    }
  }

  componentDidMount() {
    const {
      GET_DRAFT_POST,
      GET_DRAFT_PAGE,
      GET_PREVIEW,
    } = this.props

    document.title = 'MonEntrepriz - preview'

    const params = queryString.parse(location.search)

    // Brouillon
    if (params.p !== undefined) {
      GET_DRAFT_POST(params)
    }
    if (params.page_id !== undefined) {
      GET_DRAFT_PAGE(params)
    }
    // Preview page ou post
    if (params.preview_id !== undefined) {
      GET_PREVIEW(params)
    }
  }

  componentDidUpdate() {
    const {
      previewPost,
      previewPage,
    } = this.props

    // const {
    //   linkImg,
    // } = this.state

    if (previewPost.length !== 0) {
      this.createPreviewPost()
    }

    if (previewPage.length !== 0) {
      this.createPreviewPage()
    }

    // if (!linkImg) {
    //   this.getImg()
    // }
  }

  // getImg = () => {
  //   const {
  //     dataMedia,
  //   } = this.props;

  //   const {
  //     featuredMediaCurrent,
  //   } = this.state

  //   _.map(dataMedia, (currentDataMedia) => {
  //     if (currentDataMedia.data.id === featuredMediaCurrent) {
  //       this.setState({
  //         linkImg: currentDataMedia.data.guid.rendered,
  //       })
  //     }
  //   })
  // }

  createPreviewPost() {
    const {
      previewPost,
      // GET_MEDIA_ASYNC,
    } = this.props

    // if (previewPost[0].id === parseInt(id, 10)) {
    // if (previewPost[0].featured_media !== 0) {
    //   GET_MEDIA_ASYNC(previewPost[0].featured_media)

    //   this.setState({ featuredMediaCurrent: previewPost[0].featured_media })
    // }
    // }

    this.setState({
      title: previewPost[0].title.rendered,
      content: previewPost[0].content.rendered,
    })
  }

  createPreviewPage() {
    const {
      previewPage,
    } = this.props

    this.setState({
      title: previewPage[0].title.rendered,
      content: previewPage[0].content.rendered,
    })
  }

  render() {
    const {
      title,
      content,
      linkImg,
    } = this.state;

    const regExScript = /<script\b[^>]*>([\s\S]*?)<\/script>/gm
    const scripts = regExScript.exec(content)
  
    const js = () => (
      <script type="text/javascript">
        {_.map(scripts, (s) => {
          const j = s.replace(/<script\b[^>]*>/gm).replace(/<\/script>/gm)
          eval(j || null)
        })}
      </script>
    )

    return (
      
      <div id="Container">
        <div id="post-standard">
          <div id="single-post">
            <img className="img-standard-article" src={linkImg} />
            <h1>{ReactHtmlParser(title)}</h1>
            <div>{ReactHtmlParser(content)}</div>
          </div>
          {js()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataArticle: state.preview.dataArticle,
  previewPost: state.preview.previewPost,
  previewPage: state.preview.previewPage,
  dataMedia: state.home.dataMedia,
})

const mapDispatchToProps = (dispatch) => ({
  GET_DRAFT_POST: (params) => dispatch({ type: 'GET_DRAFT_POST', params }),
  GET_DRAFT_PAGE: (params) => dispatch({ type: 'GET_DRAFT_PAGE', params }),
  GET_PREVIEW: (params) => dispatch({ type: 'GET_PREVIEW', params }),
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
