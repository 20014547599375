import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import SinglePage from '../../components/singlePage'

class Press extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    dataSinglePage: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      pageAbout: 3396,
    }
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(3396)
    // document.title = 'MonEntrepriz - À propos'
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 3396)
    if (current) {
      const { yoast_meta } = current.data
      document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
      if (yoast_meta) {
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  render() {
    const {
      pageAbout,
    } = this.state
    
    return (
      <div id="Container" ref={this.element}>
        <div id="princing-part-title">
          <h1>Lu, vu, et entendu dans les médias</h1>
        </div>
        <SinglePage id={pageAbout} noTitle />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Press)
