import axios from 'axios'
import config from '../../../config'

const urlMedia = `${config.urlWp}media/`;

const getMediadiaByFeaturedMedia = (featuredMedia) => axios({
  method: 'get',
  baseURL: `${urlMedia}${featuredMedia}`,
})

export default {
  getMediadiaByFeaturedMedia,
}
