import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  getHeadResult,
  getFooterResult,
} from './actions'

const defaultError = {
  type: 'TOASTER',
  item: {
    body: 'Une erreur est survenue',
    validity: 5,
    type: 'error',
  },
}

function* getHeadAsync(params) {
  try {
    const { data: html } = yield call(me.getHead, params)
    yield put(getHeadResult(html))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

function* getFooterAsync(params) {
  try {
    const { data: html } = yield call(me.getFooter, params)
    yield put(getFooterResult(html))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

export function* fetchResult() {
  yield takeEvery('GET_HEAD', getHeadAsync)
  yield takeEvery('GET_FOOTER', getFooterAsync)
}

export default fetchResult
