import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import NewsLetter from '../../components/newsLetter'
import LastPosts from '../../components/lastPosts'
import SinglePage from '../../components/singlePage'
import MenuCategories from '../../components/menuCategories'
import LastPostsByCategorie from '../postsByCategorie/lastPostsByCategorie'

class Blog extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    dataSinglePage: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      idSimoz: 2251,
    }
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(15)
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 15)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  render() {
    const {
      idSimoz,
    } = this.state

    return (
      <div id="Container">
        <div id="princing-part-title">
          <h1>Blog</h1>
        </div>
        <MenuCategories />
        <div id="articles-blog">
          <div id="part-of-article">
            <LastPosts type="card" />
          </div>
          <div id="part-of-last-article">
            <LastPosts type="title" />
          </div>
        </div>
        
        <NewsLetter />

        {/* <div id="articles-bottom">
          <LastPostsByCategorie idCategorie={9} />

          <LastPostsByCategorie idCategorie={8} />
          
          <LastPostsByCategorie idCategorie={2} />

          <LastPostsByCategorie idCategorie={19} />
        </div> */}

        <SinglePage id={idSimoz} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Blog)
