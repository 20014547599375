import _ from 'lodash'
import {
  S_GET_HEAD,
  S_GET_FOOTER,
} from './actions'

const initialState = {
  head: null,
  footer: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case S_GET_HEAD:
      return {
        ...state,
        head: action.head,
      }
    case S_GET_FOOTER:
      return {
        ...state,
        footer: action.footer,
      }
    default:
      return state
  }
}
