import React from 'react'
import ReactDOM from 'react-dom'
import '@babel/polyfill'
import Router from './redux/router'
import './assets/styles/index.scss'
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Router />,
  document.getElementById('root'),
)

// registerServiceWorker()
