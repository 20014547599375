import axios from 'axios'
import config from '../config'

const getPages = ({ id }) => (id ? axios({
  method: 'get',
  baseURL: `${config.baseURL}//wp/v2/pages/${id}`,
}) : null)

export default {
  getPages,
}
