import { all, fork } from 'redux-saga/effects'
import api from '../api/sagas'
import home from '../pages/home/api/sagas'
import header from '../components/header/api/sagas'
import lastPosts from '../components/lastPosts/api/sagas'
import singlePage from '../components/singlePage/api/sagas'
import singlePost from '../components/singlePost/api/sagas'
import categories from '../components/categories/api/sagas'
import allPosts from '../pages/allPosts/api/sagas'
import postsByCategorie from '../pages/postsByCategorie/api/sagas'
import search from '../components/menuCategories/api/sagas'
import menu from '../components/menu/api/sagas'
import footer from '../components/footer/api/sagas'
import preview from '../pages/preview/api/sagas'
import contact from '../pages/contact/api/sagas'

const sagas = [
  api,
  home,
  header,
  lastPosts,
  singlePage,
  singlePost,
  categories,
  allPosts,
  postsByCategorie,
  search,
  menu,
  footer,
  preview,
  contact,
]

function* rootSaga() {
  yield all(
    sagas.map((saga) => fork(saga)),
  )
}

export default rootSaga
