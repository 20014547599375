import {
  GET_MEDIA,
} from './actions'

const initialState = {
  dataMedia: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MEDIA:
      return {
        ...state,
        dataMedia: [...state.dataMedia, { id: action.dataMedia.post, data: action.dataMedia }],
      }
    default:
      return state
  }
}
