import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

class Pagination extends PureComponent {
  static propTypes = {
    numberOfPages: PropTypes.number.isRequired,
    SET_CURRENT_PAGE: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    SET_CURRENT_PATHNAME: PropTypes.func.isRequired,
    pathnamePagination: PropTypes.string,
  }

  static defaultProps = {
    currentPage: 1,
    pathnamePagination: null,
  }

  componentDidMount() {
    const {
      SET_CURRENT_PAGE,
      SET_CURRENT_PATHNAME,
      // currentPage,
      pathnamePagination,
    } = this.props
    
    // SET_CURRENT_PAGE(1)
    
    // console.log('************************************************ current page did mount : ', currentPage)
    // console.log('MOUNT pathname => ', location.pathname)
    // console.log('MOUNT pathname stoker=> ', pathnamePagination)
    
    this.scrollToUp()
    if (pathnamePagination !== location.pathname) {
      SET_CURRENT_PAGE(1)
      SET_CURRENT_PATHNAME(location.pathname)
    }
    this.styleCurrentPage()
  }

  componentDidUpdate() {
    const {
      // currentPage,
      pathnamePagination,
      SET_CURRENT_PAGE,
      SET_CURRENT_PATHNAME,
    } = this.props

    if (pathnamePagination !== location.pathname) {
      // console.log('UPDATE ==================> pas egal !!!!!')
      SET_CURRENT_PATHNAME(location.pathname)
      SET_CURRENT_PAGE(1)
    }
    this.scrollToUp()
    this.styleCurrentPage()
  }

  getcurrentPage(e) {
    const {
      numberOfPages,
      currentPage,
    } = this.props

    const type = e.currentTarget.className
    const page = parseInt(e.currentTarget.textContent, 10)

    if (type === 'pagination-back') {
      if (currentPage !== 1) {
        this.updateCurrentPage('back', currentPage)
      }
    } else if (type === 'pagination-next') {
      if (currentPage !== numberOfPages) {
        this.updateCurrentPage('next', currentPage)
      }
    } else {
      this.updateCurrentPage('set', page)
    }
  }

  updateCurrentPage = (type, page) => {
    const {
      SET_CURRENT_PAGE,
    } = this.props

    if (type === 'back') {
      SET_CURRENT_PAGE(page - 1)
    }

    if (type === 'next') {
      SET_CURRENT_PAGE(page + 1)
    }

    if (type === 'set') {
      SET_CURRENT_PAGE(page)
    } 
  }
  
  scrollToUp() {
    window.scrollTo(0, 0)
  }

  styleCurrentPage() {
    const {
      currentPage,
    } = this.props
    const listPage = document.querySelectorAll('.pagination .pagination-page')
    
    _.map(listPage, (elem) => {
      const contentLi = elem.textContent
      elem.classList.remove('current-page')

      if (parseInt(contentLi, 10) === currentPage) {
        elem.classList.add('current-page')
      }
    })
  }

  // For display pagination with lots of pages
  displayPagination() {
    const {
      numberOfPages,
      currentPage,
    } = this.props

    const listPagination = []
    let paginationHTML = null
    const min = 1
    const max = numberOfPages
    const tabsPagesPresents = []

    if (numberOfPages < 8) {
      let i = 0
      do {
        i += 1
        listPagination.push(
          <li key={i} className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{i}</li>,
        )
      } while (i < numberOfPages)

      paginationHTML = (
        <ul className="pagination">
          <li className="pagination-back" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_left </i></li>
          {listPagination}
          <li className="pagination-next" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_right </i></li>
        </ul>
      )
    } else {
      let i = 0
      do {
        i += 1

        // ... before
        if (i === currentPage - 4) {
          listPagination.push(
            <li key={i} className="pagination-page">...</li>,
          )
        }

        // ... after
        if (i === currentPage + 4) {
          listPagination.push(
            <li key={i} className="pagination-page">...</li>,
          )
        }

        // pages
        if (i > currentPage - 3 && i < currentPage + 3) {
          tabsPagesPresents.push(i)
          listPagination.push(
            <li key={i} className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{i}</li>,
          )
        }
      } while (i < numberOfPages)

      const minPresent = tabsPagesPresents.includes(min)
      const maxPresent = tabsPagesPresents.includes(max);

      if (!minPresent && !maxPresent) {
        paginationHTML = (
          <ul className="pagination">
            <li className="pagination-back" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_left </i></li>
            <li className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{min}</li>
            {listPagination}
            <li className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{max}</li>
            <li className="pagination-next" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_right </i></li>
          </ul>
        )
      } else if (!maxPresent) {
        paginationHTML = (
          <ul className="pagination">
            <li className="pagination-back" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_left </i></li>
            {listPagination}
            <li className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{max}</li>
            <li className="pagination-next" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_right </i></li>
          </ul>
        )
      } else {
        paginationHTML = (
          <ul className="pagination">
            <li className="pagination-back" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_left </i></li>
            <li className="pagination-page" onClick={(e) => this.getcurrentPage(e)}>{min}</li>
            {listPagination}
            <li className="pagination-next" onClick={(e) => this.getcurrentPage(e)}><i className="material-icons"> arrow_right </i></li>
          </ul>
        )
      }
    }

    return (
      paginationHTML
    )
  }

  render() {
    const {
      numberOfPages,
    } = this.props
    return (
      <div>
        {numberOfPages !== 1 && this.displayPagination()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.pagination.currentPage,
  pathnamePagination: state.pagination.pathnamePagination,
})

const mapDispatchToProps = (dispatch) => ({
  SET_CURRENT_PAGE: (currentPage) => dispatch({ type: 'SET_CURRENT_PAGE', currentPage }),
  SET_CURRENT_PATHNAME: (pathnamePagination) => dispatch({ type: 'SET_CURRENT_PATHNAME', pathnamePagination }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
