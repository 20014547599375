import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import SearchIcon from '@material-ui/icons/Search'
import { connect } from 'react-redux'
import Categories from '../categories'

class MenuCategories extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    SET_KEYWORDS: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      userSearch: '',
    }
  }

  componentDidMount() {
    this.searchInput.focus()
  }

  handleChange = (e) => {
    const { SET_KEYWORDS } = this.props
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      const { userSearch } = this.state
      SET_KEYWORDS(userSearch)
    })
  }

  handleKeyDown = (e) => {
    const { history } = this.props
    if (e.key === 'Enter') {
      history.push('/search')
    }
  }

  render() {
    return (
      <div id="menu-categories">
        <Categories />
        
        <div className="search-bar">
          <SearchIcon /> 
          <input name="userSearch" onChange={this.handleChange} onKeyPress={(e) => this.handleKeyDown(e)} ref={(input) => { this.searchInput = input }} type="search" placeholder="Votre recherche" />
        </div>

      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  SET_KEYWORDS: (keywords) => dispatch({ type: 'SET_KEYWORDS', keywords }),
})

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(MenuCategories)
