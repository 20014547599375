import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import HtmlReactParser from 'html-react-parser'
import { componentWillAppendToBody } from 'react-append-to-body'
import yticone from '../../assets/images/youtubeIcone.svg'
import fbIcone from '../../assets/images/facebookIcone.svg'
import inIcone from '../../assets/images/inIcone.svg'
import SinglePage from '../singlePage'

function MyComponent({ children }) {
  return (
    <>
      {children}
    </>
  )
}
MyComponent.propTypes = {
  children: PropTypes.any,
}
MyComponent.defaultProps = {
  children: null,
}

const AppendedMyComponent = componentWillAppendToBody(MyComponent)

class Footer extends PureComponent {
  static propTypes = {
    GET_FOOTER_ASYNC: PropTypes.func.isRequired,
    GET_FOOTER: PropTypes.func.isRequired,
    dataFooter: PropTypes.object,
    footer: PropTypes.string,
  }

  static defaultProps = {
    dataFooter: null,
    footer: null,
  }

  componentDidMount() {
    const {
      GET_FOOTER_ASYNC,
      GET_FOOTER,
    } = this.props;

    GET_FOOTER_ASYNC()
    GET_FOOTER()
  }
  
  // componentDidUpdate() {
  //   const { footer } = this.props
  //   if (footer) this.injectFooter(footer)
  // }

  injectFooter = () => {
    const { footer } = this.props
    if (!footer) return null
    return HtmlReactParser(footer)
  } 
  // {
  //   // console.log(footer.replace(/<\!--.*?-->/g, ''))
  //   // const foot = new DOMParser().parseFromString(footer.replace(/<\!--.*?-->/g, ''), 'application/xml')
  //   console.log()
  //   // document.body.appendChild()
  //   // const headers = this.stringToDom(head)
  //   // const metas = headers.querySelectorAll('meta')
  //   // const styles = headers.querySelectorAll('style')
  //   // const scripts = headers.querySelectorAll('script')
  //   // const links = headers.querySelectorAll('link')
  //   // setTimeout(() => {
  //   //   _.map(metas, (meta) => document.head.appendChild(meta))
  //   //   _.map(styles, (style) => document.head.appendChild(style))
  //   //   _.map(links, (link) => document.head.appendChild(link))
  //   //   _.map(scripts, (script) => document.body.appendChild(script))
  //   // }, 200)
  // }

  stringToDom = (str) => {
    const div = document.createElement('div')
    div.innerHTML = str.trim()
    return div;
  }

  scrollToHelp() {
    const help = document.querySelector('#help');

    if (help !== null) {
      help.scrollIntoView();
    }
  }

  verifTabSelected(e) {
    const listTabMenu = document.querySelectorAll('.tab-menu')

    _.map(listTabMenu, (elem) => {
      elem.classList.remove('tab-menu-selected')
      const hrefTab = elem.href
      if (hrefTab === e.target.href) {
        elem.classList.add('tab-menu-selected')
      }
    })
  }

  createFooter() {
    const {
      dataFooter,
    } = this.props

    const list = []
    _.map(dataFooter.items, (elem) => {
      // console.log('elem : ', elem)
      // element du Footer
      const removeLinkPreprod = 'https://monentrepriz.coi.im/';
      const removeLinkProd = 'https://www.monentrepriz.com/';
      if (elem.ID === 2527) {
        const link = elem.url.replace(removeLinkPreprod, '').replace(removeLinkProd, '')
        list.push(<li key={elem.ID}><Link to={`/${link}`} onClick={() => this.scrollToHelp()}>{elem.title}</Link></li>)
      } else {
        const link = elem.url.replace(removeLinkPreprod, '').replace(removeLinkProd, '').replace('/', '')
        list.push(<li key={elem.ID}><Link to={`/${link}`} onClick={(e) => this.verifTabSelected(e)}>{elem.title}</Link></li>)
      }
    })

    return (
      <ul>
        {list}
      </ul>
    )
  }

  render() {
    const {
      dataFooter,
    } = this.props;

    return (
      <>
        <div id="Footer">
          <div className="inner">
            <div>
              {dataFooter && this.createFooter()}
              <div id="catch-phrase-footer">L'éditeur est une société française et les données sont hébergées en France</div>
            </div>
            
            <div className="social-network">
              <p className="text">Suivez-nous sur les réseaux sociaux :</p>
              <a href="https://www.youtube.com/channel/UCr_2De-KoZ8cLNHFSDImIBg" target="_blank" rel="noopener noreferrer"><img src={yticone} /></a> 
              <a href="https://fr-fr.facebook.com/monentrepriz/" target="_blank" rel="noopener noreferrer"><img src={fbIcone} /></a> 
              <a href="https://www.linkedin.com/company/monentrepriz/?originalSubdomain=fr" target="_blank" rel="noopener noreferrer"><img src={inIcone} /></a>
            </div>
          </div>
        </div>
        <AppendedMyComponent>
          {this.injectFooter()}
        </AppendedMyComponent>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dataFooter: state.footer.dataFooter,
  footer: state.header.footer,
})

const mapDispatchToProps = (dispatch) => ({
  GET_FOOTER_ASYNC: () => dispatch({ type: 'GET_FOOTER_ASYNC' }),
  GET_FOOTER: () => dispatch({ type: 'GET_FOOTER' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
