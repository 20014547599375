import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'

class SinglePost extends PureComponent {
  static propTypes = {
    dataPost: PropTypes.object,
    dataMedia: PropTypes.array,
    GET_POST_ASYNC: PropTypes.func.isRequired,
    GET_MEDIA_ASYNC: PropTypes.func.isRequired,
    id: PropTypes.string,
  }

  static defaultProps = {
    dataPost: null,
    dataMedia: null,
    id: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      title: null,
      content: null,
      featuredMediaCurrent: null,
      linkImg: null,
    }
  }

  componentDidMount() {
    const {
      dataPost,
      GET_POST_ASYNC,
      id,
    } = this.props

    if (dataPost.id !== id) {
      GET_POST_ASYNC(id)
    }
  }

  componentDidUpdate() {
    const {
      dataPost,
    } = this.props;

    const {
      title,
      linkImg,
    } = this.state
    
    if (!linkImg) {
      this.getImg()
    }
    if (!title && dataPost.title !== undefined) {
      this.createPost()
    }

    if (dataPost) {
      const { yoast_meta } = dataPost
      if (yoast_meta) {
        document.title = `${ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')} - MonEntrepriz, Application de Gestion des Entrepreneurs`
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  getImg = () => {
    const {
      dataMedia,
    } = this.props;

    const {
      featuredMediaCurrent,
    } = this.state

    _.map(dataMedia, (currentDataMedia) => {
      if (currentDataMedia.data.id === featuredMediaCurrent) {
        this.setState({
          linkImg: currentDataMedia.data.guid.rendered,
        })
      }
    })
  }

  createPost = () => {
    const {
      dataPost,
      id,
      GET_MEDIA_ASYNC,
    } = this.props;

    if (dataPost.id === parseInt(id, 10)) {
      if (dataPost.featured_media !== 0) {
        GET_MEDIA_ASYNC(dataPost.featured_media)
        
        this.setState({ featuredMediaCurrent: dataPost.featured_media })
      }
    }

    this.setState({
      title: dataPost.title.rendered,
      content: dataPost.content.rendered,
    })
  }
  
  render() {
    const {
      title,
      content,
      linkImg,
    } = this.state;

    const regExScript = /<script\b[^>]*>([\s\S]*?)<\/script>/gm
    const scripts = regExScript.exec(content)
  
    const js = () => (
      <script type="text/javascript">
        {_.map(scripts, (s) => {
          const j = s.replace(/<script\b[^>]*>/gm).replace(/<\/script>/gm)
          eval(j || null)
        })}
      </script>
    )

    return (
      <>
        <div id="single-post">
          <div className="ariane">
            <a href="/blog">Blog</a>
            {ReactHtmlParser(title)}
          </div>
          <img className="img-standard-article" src={linkImg} />
          <h1>{ReactHtmlParser(title)}</h1>
          <div>{ReactHtmlParser(content)}</div>
        </div>
        {js()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dataPost: state.singlePost.dataPost,
  dataMedia: state.home.dataMedia,
})

const mapDispatchToProps = (dispatch) => ({
  GET_POST_ASYNC: (id) => dispatch({ type: 'GET_POST_ASYNC', id }),  
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SinglePost)
