import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import Iframe from 'react-iframe'

class Inscription extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    dataSinglePage: [],
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(501)
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 501)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  render() {
    return (
      <div id="Container" ref={this.element}>
        <Iframe
          url="https://secure25.monentrepriz.com/CELIFI?open_mode=1"
          id="inscription-Iframe"
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inscription)
