import {
  GET_POSTS_BY_CATEGORY,
  GET_LAST_POSTS_BY_CATEGORY,
} from './actions'

const initialState = {
  dataByPage: {},
  dataLastPost: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_POSTS_BY_CATEGORY:
      return {
        ...state,
        dataByPage: { 
          category: action.category, 
          numberOfPages: action.numberOfPages, 
          page: action.page, 
          data: action.dataByPage,          
        },
      }
    case GET_LAST_POSTS_BY_CATEGORY:
      return {
        ...state,
        dataLastPost: [...state.dataLastPost, { category: action.category, data: action.dataLastPost }],
      }
    default:
      return state
  }
}
