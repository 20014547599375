import axios from 'axios'
import config from '../../../config'

const getCategorieById = (id) => (id ? axios({
  method: 'get',
  baseURL: `${config.urlWp}categories/${id}`,
}) : null)

export default {
  getCategorieById,
}
