import axios from 'axios'
import config from '../../../config'

const getPostsByCategory = (category, page) => (category && page ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts?categories=${category}&page=${page}`,
}) : null)

const getLastPostsByCategory = (category) => (category ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts?categories=${category}&page=1&per_page=4`,
}) : null)

export default {
  getPostsByCategory,
  getLastPostsByCategory,
}
