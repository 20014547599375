import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import NewsLetter from '../../components/newsLetter'
import LastPosts from '../../components/lastPosts'
import MenuCategories from '../../components/menuCategories'
import ArticlesList from '../../components/articlesList'

class AllPosts extends PureComponent {
  static propTypes = {
    dataByPage: PropTypes.array,
    numberOfPages: PropTypes.number,
    GET_POSTS_BY_PAGE_ASYNC: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
  }

  static defaultProps = {
    dataByPage: null,
    numberOfPages: null,
    currentPage: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      content: null,
    }
  }

  componentDidMount() {
    const {
      GET_POSTS_BY_PAGE_ASYNC,
      currentPage,
    } = this.props
    
    document.title = 'MonEntrepriz - Tous les articles'

    GET_POSTS_BY_PAGE_ASYNC(currentPage)
  }

  componentDidUpdate() {
    const {
      dataByPage,
      GET_POSTS_BY_PAGE_ASYNC,
      currentPage,
    } = this.props

    const {
      // currentPage,
      content,
    } = this.state

    let found = false
    _.map(dataByPage, (elem) => {
      const pageElem = elem.page
      if (currentPage === pageElem) {
        found = true
        this.setState({
          content: elem.data,
        })
      }
    })

    if (found === false || content === null) {
      GET_POSTS_BY_PAGE_ASYNC(currentPage)
    }
  }
  
  render() {
    const {
      content,
    } = this.state

    const {
      numberOfPages,
    } = this.props
    if (content === null) return null
    return (
      <div id="Container">
        <MenuCategories />
        <h1>TOUS LES ARTICLES</h1>

        <div id="articles-blog">
          <ArticlesList content={content} numberOfPages={numberOfPages} />
          <div id="part-of-last-article">
            <LastPosts type="title" />
          </div>
        </div>

        <NewsLetter />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  dataByPage: state.allPosts.dataByPage,
  numberOfPages: state.allPosts.numberOfPages,
  currentPage: state.pagination.currentPage,
})

const mapDispatchToProps = (dispatch) => ({
  GET_POSTS_BY_PAGE_ASYNC: (page) => dispatch({ type: 'GET_POSTS_BY_PAGE_ASYNC', page }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllPosts)
