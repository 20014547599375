import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import SinglePage from '../../components/singlePage'
import CustomerReviews from '../../components/customerReviews'
import News from '../../components/news'
import PressReviews from '../../components/pressReviews'

class Home extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    dataSinglePage: [],
    // dataArticle: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      listPagesById: [2202, 2221, 2224, 2227, 3092, 2239, 2249, 2251],
      goToPrev: false,
      urlPreview: null,
    }
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(2247)
    // document.title = 'MonEntrepriz - Accueil';
    if (location.search !== '') {
      this.redirectToPreview()
    }
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 2247)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  redirectToPreview() {
    const urlPreview = `/preview/${location.search}`
    this.setState({
      goToPrev: true,
      urlPreview: urlPreview,
    })
  }

  listArticles() {
    const {
      listPagesById,
    } = this.state;

    const list = listPagesById.map((id) => {
      if (id === 2221) {
        return (
          <>
            <News />
            <CustomerReviews />
            <SinglePage key={id} id={id} />
          </>
        )
      }
      if (id === 2239) {
        return (
          <>
            <PressReviews />
            <SinglePage key={id} id={id} />
          </>
        )
      }
      return <SinglePage key={id} id={id} />
    })
    return (
      list
    )
  }

  render() {
    const {
      goToPrev,
      urlPreview,
    } = this.state
    return (
      <div id="Container">
        {!goToPrev && this.listArticles()}
        {goToPrev && <Redirect to={urlPreview} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataArticle: state.home.dataArticle,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
