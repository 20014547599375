export const GET_FORM = 'GET_FORM'
export const SEND_MAIL = 'SEND_MAIL'

export const getFormProperties = (data) => ({
  type: GET_FORM,
  formProperties: data,
})

export const sendEmail = (data) => ({
  type: SEND_MAIL,
  status: data,
})
