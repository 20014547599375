import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'

class Categories extends PureComponent {
  static propTypes = {
    dataListCategories: PropTypes.array,
    GET_CATEGORIE_ASYNC: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dataListCategories: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      listOrderCategorie: [9, 8, 2, 19],
      listHtml: [],
    }
  }

  componentDidMount() {
    const {
      listOrderCategorie,
    } = this.state

    const {
      dataListCategories,
      GET_CATEGORIE_ASYNC,
    } = this.props;

    if (dataListCategories.length === 0) {
      _.map(listOrderCategorie, (id) => {
        GET_CATEGORIE_ASYNC(id)
      })  
    }
  }

  createListCategorie = () => {
    const {
      listHtml,
      listOrderCategorie,
    } = this.state;

    const {
      dataListCategories,
    } = this.props;
    
    _.map(listOrderCategorie, (indexOrderCategorie) => {
      _.map(dataListCategories, (elem, id) => {
        const idCategorie = elem.id
        const title = elem.data.name
        const linkName = elem.data.slug
        
        if (indexOrderCategorie === idCategorie) {
          listHtml.push(
            <li key={id} id={idCategorie}><Link to={`/postsByCategorie/${idCategorie}/${linkName}`} className="about item-category">{title}</Link></li>,
          )
        }
      })
    })
    
    return listHtml
  }
  
  render() {
    const {
      listOrderCategorie,
    } = this.state;

    const {
      dataListCategories,
    } = this.props;

    return (
      <>
        <ol>
          {dataListCategories.length === listOrderCategorie.length && this.createListCategorie()}
        </ol>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dataListCategories: state.categories.dataListCategories,
})

const mapDispatchToProps = (dispatch) => ({
  GET_CATEGORIE_ASYNC: (id) => dispatch({ type: 'GET_CATEGORIE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
