import {
  GET_ARTICLE,
} from './actions'

const initialState = {
  dataArticle: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        dataArticle: [...state.dataArticle, { id: action.dataArticle.id, data: action.dataArticle }],
      }
    default:
      return state
  }
}
