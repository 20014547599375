import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  getPagesResult,
} from './actions'

function* getPagesAsync() {
  try {
    const { data } = yield call(me.getRefs)
    yield put(getPagesResult(data))
  } catch ({ response }) {
    console.error(response)
  }
}

export function* fetchResult() {
  yield takeEvery('GET_PAGES', getPagesAsync)
}

export default fetchResult
