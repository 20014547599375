import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  getPostsByCategory,
  getLastPostsByCategory,
} from './actions'

const defaultError = {
  type: 'TOASTER',
  item: {
    body: 'Une erreur est survenue',
    validity: 5,
    type: 'error',
  },
}

function* getPostsByCategoryAsync(params) {
  try {
    const { category, page } = params
    const { data, headers } = yield call(me.getPostsByCategory, category, page)
    const numberOfPages = parseInt(headers['x-wp-totalpages'], 10)
    
    yield put(getPostsByCategory(parseInt(category, 10), numberOfPages, page, data))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

function* getLastPostsByCategoryAsync(params) {
  try {
    const { category } = params
    const { data } = yield call(me.getLastPostsByCategory, category)
    
    yield put(getLastPostsByCategory(parseInt(category, 10), data))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

export function* fetchResult() {
  yield takeEvery('GET_POSTS_BY_CATEGORY_ASYNC', getPostsByCategoryAsync)
  yield takeEvery('GET_LAST_POSTS_BY_CATEGORY_ASYNC', getLastPostsByCategoryAsync)
}

export default fetchResult
