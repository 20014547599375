export const STATE_DRAWER = 'STATE_DRAWER'
export const GET_MENU = 'GET_MENU'

export const setStateDrawer = (boolDrawer) => ({
  type: STATE_DRAWER,
  stateDrawer: boolDrawer,
})

export const getMenu = (data) => ({
  type: GET_MENU,
  dataMenu: data,
})
