import axios from 'axios'
import config from '../../../config'

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const result = parts.pop().split(';').shift()
    return result;
  }
  return false;
}

const getPreviewPost = (p) => (p ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts/${p}/revisions/`,
  headers: {
    'X-WP-Nonce': getCookie('wp_nonce'),
  },
}) : null)

const getPreviewPage = (page) => (page ? axios({
  method: 'get',
  baseURL: `${config.urlWp}pages/${page}/revisions/`,
  headers: {
    'X-WP-Nonce': getCookie('wp_nonce'),
  },
}) : null)

export default {
  getPreviewPost,
  getPreviewPage,
  getCookie,
}
