import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  getArticlesResults,
} from './actions'

function* searchArticlesAsync(params) {
  try {
    const { data } = yield call(me.searchArticle, params)
    yield put(getArticlesResults(data))
  } catch ({ response }) {
    console.error(response)
  }
}

export function* fetchResult() {
  yield takeEvery('SEARCH_ARTICLES', searchArticlesAsync)
}

export default fetchResult
