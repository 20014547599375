export const SEARCH_ITEM = 'SEARCH_ITEM'
export const SET_KEYWORDS = 'SET_KEYWORDS'

export const getArticlesResults = (data) => ({
  type: SEARCH_ITEM,
  search: data,
})

export const getCurrentSearch = (keywords) => ({
  type: SET_KEYWORDS,
  keywords: keywords,
})
