/* eslint-disable arrow-body-style */
import React from 'react'
import SinglePage from '../singlePage'

const CustomerReviews = () => {
  return (
    <div className="reviews">
      <SinglePage id={3182} />
    </div>
  )
}

export default CustomerReviews
