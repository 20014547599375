import axios from 'axios'
import config from '../../../config'

// const urlWp = 'https://monentrepriz.coi.im/wp-json/wp/v2/';

const getMenu = ({ path }) => axios({
  method: 'get',
  baseURL: `${config.urlMenus}${path}`,
})

export default {
  getMenu,
}
