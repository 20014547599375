import {
  SET_CURRENT_PAGE,
  SET_CURRENT_PATHNAME,
} from './actions'

const initialState = {
  currentPage: 1,
  pathnamePagination: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case SET_CURRENT_PATHNAME:
      return {
        ...state,
        pathnamePagination: action.pathnamePagination,
      }
    default:
      return state
  }
}
