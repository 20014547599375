import {
  GET_POSTS_BY_PAGE,
} from './actions'

const initialState = {
  dataByPage: [],
  numberOfPages: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_POSTS_BY_PAGE:
      return {
        ...state,
        dataByPage: [...state.dataByPage, { page: action.page, data: action.dataByPage }],
        numberOfPages: action.numberOfPages,
      }
    default:
      return state
  }
}
