import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import SinglePage from '../../components/singlePage'

import pascalFerron from '../../assets/images/equipe/pascal-ferron.jpg'
import jeanmarcBonnet from '../../assets/images/equipe/jean-marc-bonnet.jpg'
import sarahFournier from '../../assets/images/equipe/sarah-fournier.jpg'
import delphineHuet from '../../assets/images/equipe/delphine-huet.jpg'
import melindaTexeira from '../../assets/images/equipe/melinda-texeira.jpg'
import sylvainNassiri from '../../assets/images/equipe/sylvain-nassiri.jpg'
import jeromeGellibert from '../../assets/images/equipe/jerome-gellibert.jpg'
import nellyGillardie from '../../assets/images/equipe/nelly-gillardie.jpg'
import laurentJeanneau from '../../assets/images/equipe/laurent-jeanneau.jpg'
import fredericMoreno from '../../assets/images/equipe/frederic-moreno.jpg'
import alainSouloumiac from '../../assets/images/equipe/alain-souloumiac.jpg'
import stephaneGuivy from '../../assets/images/equipe/stephane-guivy.jpg'
import cedricGrosjean from '../../assets/images/equipe/cedric-grosjean.jpg'
import albertBelmonte from '../../assets/images/equipe/albert-belmonte.jpg'
import coralieLargy from '../../assets/images/equipe/coralie-largy.jpg'

class About extends PureComponent {
  static propTypes = {
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    dataSinglePage: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      pageAbout: 2257,
    }
  }

  componentDidMount() {
    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(2257)
    // document.title = 'MonEntrepriz - À propos'
  }

  componentDidUpdate() {
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 2247)
    if (current) {
      const { yoast_meta } = current.data
      document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
      if (yoast_meta) {
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  render() {
    const {
      pageAbout,
    } = this.state
    
    return (
      <div id="Container" ref={this.element}>
        <div id="princing-part-title">
          <h1>Qui sommes-nous ?</h1>
        </div>
        <SinglePage id={pageAbout} noTitle />
        <div className="article team" id="article_2257">
          <h3 className="team">L’équipe</h3>
          <div className="members">
            <div className="member">
              <img src={pascalFerron} /><br />
              <h4>Pascal FERRON</h4>
              <span>Directeur Général du Groupe<br />FIMECO Walter France</span>
            </div>
            <div className="member">
              <img src={nellyGillardie} /><br />
              <h4>Nelly GILLARDIE</h4>
              <span>Expert-comptable</span>
            </div>
            <div className="member">
              <img src={laurentJeanneau} /><br />
              <h4>Laurent JEANNEAU</h4>
              <span>Expert-comptable</span>
            </div>
            <div className="member">
              <img src={jeromeGellibert} /><br />
              <h4>Jérôme GELLIBERT</h4>
              <span>Expert-comptable</span>
            </div>
            <div className="member">
              <img src={delphineHuet} /><br />
              <h4>Delphine HUET</h4>
              <span>Juriste</span>
            </div>
            <div className="member">
              <img src={alainSouloumiac} /><br />
              <h4>Alain SOULOUMIAC</h4>
              <span>Product Owner</span>
            </div> 
            <div className="member">
              <img src={cedricGrosjean} /><br />
              <h4>Cédric GROSJEAN</h4>
              <span>Développeur</span>
            </div>
            <div className="member">
              <img src={stephaneGuivy} /><br />
              <h4>Stéphane GUIVY</h4>
              <span>Développeur</span>
            </div>
            <div className="member">
              <img src={albertBelmonte} /><br />
              <h4>Albert BELMONTE</h4>
              <span>Développeur</span>
            </div>
            <div className="member">
              <img src={coralieLargy} /><br />
              <h4>Coralie LARGY</h4>
              <span>Webdesigner</span>
            </div>
            <div className="member">
              <img src={melindaTexeira} /><br />
              <h4>Mélinda TEXEIRA</h4>
              <span>Responsable<br />Communication</span>
            </div>
            <div className="member">
              <img src={sylvainNassiri} /><br />
              <h4>Sylvain NASSIRI</h4>
              <span>Community Manager</span>
            </div>
            <div className="member">
              <img src={fredericMoreno} /><br />
              <h4>Frédéric MORENO</h4>
              <span>Directeur Filiale<br />Acropole Expert Informatique</span>
            </div>
            <div className="member">
              <img src={jeanmarcBonnet} /><br />
              <h4>Jean-Marc BONNET</h4>
              <span>Gérant de FINOVA Conseil</span>
            </div>
            <div className="member">
              <img src={sarahFournier} /><br />
              <h4>Sarah FOURNIER</h4>
              <span>Gérante de Proactif<br />Recrutement</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(About)
