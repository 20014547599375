import React, { PureComponent } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import CookieConsent from 'react-cookie-consent'
import _ from 'lodash'
import Menu from '../menu'
import Drawer from '../drawer'

class Header extends PureComponent {
  static propTypes = {
    stateDrawer: PropTypes.bool,
    GET_HEAD: PropTypes.func.isRequired,
    head: PropTypes.string,
  }

  static defaultProps = {
    stateDrawer: false,
    head: null,
  }

  componentDidMount() {
    const { GET_HEAD } = this.props
    GET_HEAD()
  }

  componentDidUpdate() {
    this.injectHeader()
  }

  injectHeader = () => {
    const { head } = this.props
    if (!head) return null
    const headers = this.stringToDom(head)
    const metas = headers.querySelectorAll('meta')
    const styles = headers.querySelectorAll('style')
    const scripts = headers.querySelectorAll('script')
    const links = headers.querySelectorAll('link')
    _.map(metas, (meta) => document.head.appendChild(meta))
    _.map(styles, (style) => document.head.appendChild(style))
    _.map(links, (link) => document.head.appendChild(link))
    _.map(scripts, (script) => document.head.appendChild(script))
    return null
  }

  stringToDom = (str) => {
    const div = document.createElement('div')
    div.innerHTML = (str || '').trim()
    return div;
  }

  render() {
    const {
      stateDrawer,
    } = this.props;

    return (
      <>
        <div id="Header">
          <div className="inner">
            <Menu />
            {stateDrawer && <Drawer />}
          </div>
        </div>
        <CookieConsent
          buttonText="OK"
          style={{
            color: '#FFF',
            fontSize: 12,
          }}
          buttonStyle={{
            background: '#DB0A6F',
            borderRadius: 4,
            color: '#FFF',
            fontSize: 12,
          }}
        >
          En poursuivant votre navigation sur ce site, vous acceptez l’utilisation des cookies nous permettant de mesurer l’audience de notre site
        </CookieConsent>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  stateDrawer: state.menu.stateDrawer,
  head: state.header.head,
})

const mapDispatchToProps = (dispatch) => ({
  setStateDrawer: (boolDrawer) => dispatch({ type: 'STATE_DRAWER', boolDrawer }),
  GET_HEAD: () => dispatch({ type: 'GET_HEAD' }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
