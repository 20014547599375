import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import ArticlesList from '../../components/articlesList'
import MenuCategories from '../../components/menuCategories'

class SearchResults extends PureComponent {
  static propTypes = {
    search: PropTypes.array.isRequired,
    keywords: PropTypes.string.isRequired,
    numberOfPages: PropTypes.number,
    SEARCH_ARTICLES: PropTypes.func.isRequired,
  }

  static defaultProps = {
    numberOfPages: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
    }
  }

  componentDidMount() {
    const { keywords, SEARCH_ARTICLES } = this.props

    document.title = 'MonEntrepriz - Recherche'

    SEARCH_ARTICLES(keywords)
  }

  componentDidUpdate(prevProps) {
    const { keywords, SEARCH_ARTICLES } = this.props
    const { keywords: keywordsOld } = prevProps
    if (!_.isEqual(keywords, keywordsOld)) {
      SEARCH_ARTICLES(keywords)
    }
  }

  updateCurrentPage = (type, page) => {
    if (type === 'back') {
      this.setState({
        currentPage: page - 1,
      })
    }

    if (type === 'next') {
      this.setState({
        currentPage: page + 1,
      })
    }

    if (type === 'set') {
      this.setState({
        currentPage: page,
      })
    } 
  }

  render() {
    const {
      search, numberOfPages, keywords,
    } = this.props
    const { currentPage } = this.state
    if (keywords === '') return <h2>Aucun résultat</h2>
    return (
      <>
        <MenuCategories keyword={keywords} />
        <h2 style={{ textAlign: 'center' }}>{`Recherche « ${keywords} »`}</h2>
        <ArticlesList content={search} currentPage={currentPage} updateCurrentPage={this.updateCurrentPage} numberOfPages={numberOfPages} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  numberOfPages: state.allPosts.numberOfPages,
  search: state.search.search,
  keywords: state.search.keywords,
})

const mapDispatchToProps = (dispatch) => ({
  SEARCH_ARTICLES: (search) => dispatch({ type: 'SEARCH_ARTICLES', search }),
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SearchResults)
