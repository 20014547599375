import axios from 'axios'
import config from '../../../config'

const getArticeById = (id) => (id ? axios({
  method: 'get',
  baseURL: `${config.urlWp}pages/${id}`,
}) : null)

export default {
  getArticeById,
}
