import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'

class LastPosts extends PureComponent {
  static propTypes = {
    dataLastPosts: PropTypes.array,
    dataMedia: PropTypes.array,
    GET_LAST_POSTS_ASYNC: PropTypes.func.isRequired,
    GET_MEDIA_ASYNC: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    dataLastPosts: null,
    dataMedia: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      searchMedia: false,
    }
  }

  componentDidMount() {
    const {
      GET_LAST_POSTS_ASYNC,
      dataLastPosts,
    } = this.props

    if (_.isEmpty(dataLastPosts)) GET_LAST_POSTS_ASYNC()
  }

  componentDidUpdate() {
    const {
      dataLastPosts,
      GET_MEDIA_ASYNC,
    } = this.props

    const {
      searchMedia,
    } = this.state

    if (dataLastPosts) {
      _.map(dataLastPosts, (currentData) => {
        const featuredMedia = currentData.featured_media
        if (featuredMedia !== 0) {
          if (!searchMedia) {
            GET_MEDIA_ASYNC(featuredMedia)
          }
          this.setState({
            searchMedia: true,
          })
        }
      })
    }
  }

  createLastPosts = () => {
    const {
      dataLastPosts,
      dataMedia,
      type,
    } = this.props

    const componentLastPost = []
    // CHOICE ENTER 3 TYPES OF LASTPOST : home / title / card
    if (type === 'home') {
      _.map(dataLastPosts, (currentData, index) => {
        if (index < 2) {
          const featuredMedia = currentData.featured_media
          let linkImg = ''
          const title = currentData.title.rendered
          const idPost = currentData.id
          const linkPost = currentData.slug
          const currentDate = currentData.date
          const excerpt = currentData.excerpt.rendered
          if (featuredMedia) {
            _.map(dataMedia, (currentDataMedia) => {
              // DONT USE post(id) in here, they are null => currentDataMedia.data.id === featuredMedia
              const mediaId = currentDataMedia.data.id
              if (mediaId === featuredMedia) {
                linkImg = currentDataMedia.data.guid.rendered
              }
            })
          }
          // create post here
          componentLastPost.push(
            <Link key={index} to={`/blog/${idPost}/${linkPost}`} className="card-post">
              <div className="img-card-post" style={{ backgroundImage: `url(${linkImg})` }}> </div>
              <div className="content-parent">
                <div className="content-card-post">
                  <h3>{ReactHtmlParser(title)}&nbsp;<span>/&nbsp;{moment(currentDate).format('D MMM YYYY')}</span></h3>
                  <p>{ReactHtmlParser(excerpt)}</p>
                  <span to={`/blog/${idPost}/${linkPost}`} className="about"><button type="button" className="button quaternary">Lire la suite</button></span>
                </div>
              </div>
            </Link>,
          )
        }
      })
      return (
        <div className="card-last-posts">
          {componentLastPost}
        </div>
      )
    } 
    if (type === 'title') {
      _.map(_.slice(dataLastPosts, 0, 5), (currentData, index) => {
        const title = currentData.title.rendered
        const currentDate = currentData.date
        const idPost = currentData.id
        const linkPost = currentData.slug
        
        componentLastPost.push(
          <div key={index} className="last-post">
            <Link to={`/blog/${idPost}/${linkPost}`} className="about"><h3>{ReactHtmlParser(title)}<span>  / {moment(currentDate).format('D MMM YYYY')}</span></h3></Link>
          </div>,
        )
      })

      return (
        <div>
          <h2>Derniers articles</h2>
          {componentLastPost}
        </div>
      )
    } 
    if (type === 'card') {
      _.map(dataLastPosts, (currentData, index) => {
        const featuredMedia = currentData.featured_media
        let linkImg = '/placeholder.jpg'
        const title = currentData.title.rendered
        const currentDate = currentData.date
        const idPost = currentData.id
        const linkPost = currentData.slug
        const excerpt = currentData.excerpt.rendered

        if (featuredMedia) {
          _.map(dataMedia, (currentDataMedia) => {
            // DONT USE post(id) in here, they are null => currentDataMedia.data.id === featuredMedia
            const mediaId = currentDataMedia.data.id
            if (mediaId === featuredMedia) {
              linkImg = currentDataMedia.data.guid.rendered
            }
          })
        }
        // create post here
        componentLastPost.push(
          <Link key={index} to={`/blog/${idPost}/${linkPost}`} className="card-post">
            <div className="img-card-post" style={{ backgroundImage: `url(${linkImg})` }}> </div>
            <div className="content-parent">
              <div className="content-card-post">
                <h3>{ReactHtmlParser(title)}<span>/ {moment(currentDate).format('D MMM YYYY')} </span></h3>
                <p>{ReactHtmlParser(excerpt)}</p>
                <Link to={`/blog/${idPost}/${linkPost}`} className="about"><button type="button" className="button quaternary">Lire la suite</button></Link>
              </div>
            </div>
          </Link>,
        )
      })
      return (
        componentLastPost
      )
    }
    return false
  }

  render() {
    return (
      <>
        {this.createLastPosts()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dataLastPosts: state.lastPosts.dataLastPosts,
  dataMedia: state.home.dataMedia,
})

const mapDispatchToProps = (dispatch) => ({
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
  GET_LAST_POSTS_ASYNC: () => dispatch({ type: 'GET_LAST_POSTS_ASYNC' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastPosts)
