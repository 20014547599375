import axios from 'axios'
import config from '../../../config'

const getPostsByPage = (page) => (page ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts?page=${page}`,
}) : null)

export default {
  getPostsByPage,
}
