import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'

class Drawer extends PureComponent {
  static propTypes = {
    setStateDrawer: PropTypes.func.isRequired,
    GET_MENU_ASYNC: PropTypes.func.isRequired,
    dataMenu: PropTypes.object,
  }

  static defaultProps = {
    dataMenu: null,
  }

  componentDidMount() {
    const {
      GET_MENU_ASYNC,
    } = this.props;

    GET_MENU_ASYNC()
  }

  componentDidUpdate() {
    this.verifTabComponentdidUpdate()
  }

  loginRedirect = () => {
    window.location = 'https://secure25.monentrepriz.com/CELIFI'
  }

  registerRedirect = () => {
    window.location = 'https://secure25.monentrepriz.com/CELIFI?open_mode=1'
  }

  buttonRedirect(url) {
    window.location = url
  }

  verifTabComponentdidUpdate() {
    const listTabMenu = document.querySelectorAll('.tab-menu')
    const url = location.href

    _.map(listTabMenu, (elem) => {
      elem.classList.remove('tab-menu-selected')
      
      const hrefTab = elem.href
      if (hrefTab === url) {
        elem.classList.add('tab-menu-selected')
      }
      const urlAccueil = `${location.origin}/`
      if (elem.innerHTML === 'Accueil' && url === urlAccueil) {
        elem.classList.add('tab-menu-selected')
      }
    })
  }

  createMenu() {
    const {
      dataMenu,
      setStateDrawer,
    } = this.props

    const list = []

    _.map(dataMenu.items, (elem) => {
      if (elem.url === 'https://secure25.monentrepriz.com/CELIFI' || elem.url === 'https://secure25.monentrepriz.coi.im/CELIFI') {
        // button se connecter
        list.push(<li key={elem.ID}><button onClick={() => this.buttonRedirect(elem.url)} type="button" className="button secondary">{elem.title}</button></li>)
      } else if (elem.url === 'https://secure25.monentrepriz.com/CELIFI?open_mode=1' || elem.url === 'https://secure25.monentrepriz.coi.im/CELIFI?open_mode=1') {
        // button se connecter
        list.push(<li key={elem.ID}><button onClick={() => this.buttonRedirect(elem.url)} type="button" className="button secondary full">{elem.title}</button></li>)
      } else {
        // element du menu
        const removeLinkPreprod = 'https://monentrepriz.coi.im/';
        const removeLinkProd = 'https://www.monentrepriz.com/';
        const link = elem.url.replace(removeLinkPreprod, '').replace(removeLinkProd, '').replace('/', '')
        if (elem.url === 'https://monentrepriz.coi.im/inscription/' || elem.url === 'https://www.monentrepriz.com/inscription/') {
          list.push(<li key={elem.ID}><Link onClick={() => setStateDrawer(false)} to={`/${link}`} className="tab-menu buton-menu"><button type="button" className="button secondary full">{elem.title}</button></Link></li>)
        } else {
          list.push(<li key={elem.ID}><Link onClick={() => setStateDrawer(false)} to={`/${link}`} className="tab-menu">{elem.title}</Link></li>)
        }
      }
    })

    return (
      <ul>
        <li><div className="button red close" onClick={() => setStateDrawer(false)}><div>x</div></div></li>
        {list}
      </ul>
    )
  }

  render() {
    const {
      dataMenu,
      setStateDrawer,
    } = this.props;

    return (
      <div>
        <menu id="menu-drawer">
          {dataMenu && this.createMenu()}
        </menu>
        <div id="layer" onClick={() => setStateDrawer(false)}> </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  stateDrawer: state.menu.stateDrawer,
  dataMenu: state.menu.dataMenu,
})

const mapDispatchToProps = (dispatch) => ({
  setStateDrawer: (boolDrawer) => dispatch({ type: 'STATE_DRAWER', boolDrawer }),
  GET_MENU_ASYNC: () => dispatch({ type: 'GET_MENU_ASYNC' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
