/* eslint-disable arrow-body-style */
import React from 'react'
import SinglePage from '../singlePage'

const PressReviews = () => {
  return (
    <div className="pressReviews">
      <h4>ON EN PARLE DANS LES MEDIAS</h4>
      <SinglePage id={3393} />
    </div>
  )
}

export default PressReviews
