import axios from 'axios'
import config from '../../../config'

const searchArticle = ({ search }) => (search ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts?search=${search}`,
}) : null)

export default {
  searchArticle,
}
