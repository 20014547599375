import {
  GET_LAST_POSTS,
} from './actions'

const initialState = {
  dataLastPosts: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LAST_POSTS:
      return {
        ...state,
        dataLastPosts: action.dataLastPosts,
      }
    default:
      return state
  }
}
