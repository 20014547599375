import React, { PureComponent } from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import queryString from 'query-string'
import {
  Home,
  Pricing,
  Blog,
  Faq,
  Cguv,
  HelpCenter,
  LegalNotice,
  Post,
  PostsByCategorie,
  AllPosts,
  SearchResults,
  About,
  Inscription,
  Preview,
  Application,
  Contact,
  Landing,
  Press,
} from '../pages'
import Header from '../components/header'
import Footer from '../components/footer'

class App extends PureComponent {
  componentDidMount() {
    const originUrl = location.origin
    const searchUrl = location.search
    const params = queryString.parse(location.search)

    if (params.preview_id !== undefined && location.pathname !== '/preview/') {
      if (location.pathname !== '/') {
        const urlPreview = `/${searchUrl}`
        window.location = `/${searchUrl}`
      }
    }
  }

  render() {
    return (
      <Router>
        <Header />
        <div className="routes">
          <Route exact path="/" component={Home} />
          <Route exact path="/accueil" component={Home} />
          <Route exact path="/tarifs" component={Pricing} />          
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/faq-monentrepriz/:help" component={Faq} />
          <Route exact path="/faq-monentrepriz" component={Faq} />
          <Route exact path="/conditions-generales-dutilisation-et-de-vente-site-web-et-services-monentrepriz-com" component={Cguv} />
          <Route exact path="/helpCenter" component={HelpCenter} />
          <Route exact path="/mentions-legales" component={LegalNotice} />
          <Route exact path="/blog/:id/:link" component={Post} />
          <Route exact path="/postsByCategorie/:id/:link" component={PostsByCategorie} />
          <Route exact path="/allPosts" component={AllPosts} />
          <Route exact path="/search" component={SearchResults} />
          <Route exact path="/a-propros" component={About} />
          <Route exact path="/qui-sommes-nous" component={About} />
          <Route exact path="/inscription" component={Landing} />
          <Route exact path="/preview/" component={Preview} />
          <Route exact path="/application/" component={Application} />
          <Route exact path="/contact/" component={Contact} />
          <Route exact path="/presse/" component={Press} />
          {/* <Route exact path="/(landing|landing.html)" component={Landing} /> */}
          
        </div>
        <Footer />
      </Router>
    )
  }
}

export default App
