export const GET_PREVIEW_POST = 'GET_PREVIEW_POST'
export const GET_PREVIEW_PAGE = 'GET_PREVIEW_PAGE'

export const setPreviewPost = (data) => ({
  type: GET_PREVIEW_POST,
  previewPost: data,
})

export const setPreviewPage = (data) => ({
  type: GET_PREVIEW_PAGE,
  previewPage: data,
})
