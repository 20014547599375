import axios from 'axios'
import _ from 'lodash'
import config from '../../../config'

const getFormProps = () => axios({
  method: 'get',
  baseURL: `${config.baseURL}/contact-form-7/v1/contact-forms/3118/`,
})
const sendContactMail = ({ bodyFormData }) => {
  const data = new FormData()
  _.map(bodyFormData, (value, key) => data.append(key, value))
  return axios({
    method: 'post',
    baseURL: `${config.baseURL}/contact-form-7/v1/contact-forms/3118/feedback`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}

export default {
  getFormProps,
  sendContactMail,
}
