import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import Pagination from '../pagination'

class ArticlesList extends Component {
  static propTypes = {
    dataMedia: PropTypes.array,
    numberOfPages: PropTypes.number,
    GET_MEDIA_ASYNC: PropTypes.func.isRequired,
    content: PropTypes.array.isRequired,
  }
  
  static defaultProps = {
    dataMedia: null,
    numberOfPages: null,
  }

  createPosts() {
    const {
      dataMedia,
      GET_MEDIA_ASYNC,
      content,
    } = this.props

    const componentPosts = []
    _.map(content, (elem, index) => {
      const featuredMedia = elem.featured_media
      let linkImg = ''
      const title = elem.title.rendered
      const currentDate = elem.date
      const idPost = elem.id
      const linkPost = elem.slug
      const excerpt = elem.excerpt.rendered
      
      if (featuredMedia) {
        let found = false
        _.map(dataMedia, (currentDataMedia) => {
          const mediaId = currentDataMedia.data.id
          if (mediaId === featuredMedia) {
            linkImg = currentDataMedia.data.guid.rendered
            found = true
          }
        })

        if (found === false) {
          GET_MEDIA_ASYNC(featuredMedia)
        }
      }

      componentPosts.push(
        <Link key={index} to={`/blog/${idPost}/${linkPost}`} className="card-post">
          <div className="img-card-post" style={{ backgroundImage: `url(${linkImg})` }}> </div>
          <div className="content-parent">
            <div className="content-card-post">
              <h3>{ReactHtmlParser(title)}&nbsp;<span>/&nbsp;{moment(currentDate).format('D MMM YYYY')}</span></h3>
              <p>{ReactHtmlParser(excerpt)}</p>
              <Link to={`/blog/${idPost}/${linkPost}`} className="about"><button type="button" className="button quaternary">Lire la suite</button></Link>
            </div>
          </div>
        </Link>,
      )
    })

    return componentPosts
  }

  render() {
    const { numberOfPages, content } = this.props
    return (
      <div id="part-of-article">
        <div id="all-posts">
          {content !== null && this.createPosts()}
        </div>
        <div id="pagination-all-post">
          {numberOfPages && <Pagination numberOfPages={numberOfPages} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
})

const mapDispatchToProps = (dispatch) => ({
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesList)
