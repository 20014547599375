export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_CURRENT_PATHNAME = 'SET_CURRENT_PATHNAME'

export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  currentPage: currentPage,
})

export const setCurrentPathname = (pathnamePagination) => ({
  type: SET_CURRENT_PATHNAME,
  pathnamePagination: pathnamePagination,
})
