import {
  GET_POST,
} from './actions'

const initialState = {
  dataPost: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_POST:
      return {
        ...state,
        dataPost: action.dataPost,
      }
    default:
      return state
  }
}
