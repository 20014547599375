export const GET_POSTS_BY_CATEGORY = 'GET_POSTS_BY_CATEGORY'
export const GET_LAST_POSTS_BY_CATEGORY = 'GET_LAST_POSTS_BY_CATEGORY'

export const getPostsByCategory = (category, numberOfPages, page, data) => ({
  type: GET_POSTS_BY_CATEGORY,
  category: category,
  numberOfPages: numberOfPages,
  page: page,
  dataByPage: data,
})

export const getLastPostsByCategory = (category, data) => ({
  type: GET_LAST_POSTS_BY_CATEGORY,
  category: category,
  dataLastPost: data,
})
