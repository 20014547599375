import axios from 'axios'
import config from '../../../config'

const getFooter = () => axios({
  method: 'get',
  baseURL: `${config.urlMenus}Footer`,
})

export default {
  getFooter,
}
