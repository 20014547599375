import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'

class LastPostsByCategorie extends PureComponent {
  static propTypes = {
    dataLastPost: PropTypes.array,
    idCategorie: PropTypes.number,
    dataListCategories: PropTypes.array,
    GET_LAST_POSTS_BY_CATEGORY_ASYNC: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dataLastPost: null,
    idCategorie: null,
    dataListCategories: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      titleCategorie: null,
      linkName: null,
      content: null,
    }
  }

  componentDidMount() {
    const {
      idCategorie,
      GET_LAST_POSTS_BY_CATEGORY_ASYNC,
      dataLastPost,
    } = this.props

    document.title = 'MonEntrepriz - Derniers articles par catégorie'
    
    let dataLastPostExist = false
    _.map(dataLastPost, (elem) => {
      if (elem.category === idCategorie) {
        dataLastPostExist = true
      }
    })
    if (!dataLastPostExist) {
      GET_LAST_POSTS_BY_CATEGORY_ASYNC(idCategorie)
    } else {
      this.componentDidUpdate()
    }
  }

  componentDidUpdate() {
    const {
      content,
    } = this.state

    const {
      idCategorie,
      dataLastPost,
      dataListCategories,
    } = this.props

    if (content === null) {
      _.map(dataLastPost, (elem) => {
        if (elem.category === idCategorie) {
          this.setState({
            content: elem.data,
          })
        }
      })
      _.map(dataListCategories, (elem) => {
        if (elem.id === idCategorie) {
          this.setState({
            titleCategorie: elem.data.name,
            linkName: elem.data.slug,
          })
        }
      })
    }
  }
  
  createPosts() {
    const {
      content,
    } = this.state

    const componentPosts = []
    _.map(content, (elem) => {
      const title = elem.title.rendered
      const currentDate = elem.date
      const idPost = elem.id
      const linkPost = elem.slug
      const stringTitle = ReactHtmlParser(title)[0]

      componentPosts.push(
        <div key={idPost}>
          <Link to={`/blog/${idPost}/${linkPost}`} className="about">
            <h3>{stringTitle.substr(0, 30)} ...<span> {moment(currentDate).format('D MMM YYYY')} </span></h3>
          </Link>
        </div>,
      )
    })

    return (
      componentPosts
    )
  }

  render() {
    const {
      idCategorie,
    } = this.props
    
    const {
      titleCategorie,
      content,
      linkName,
    } = this.state
    
    return (
      <div className="card-articles-bottom">
        {linkName && <Link to={`/postsByCategorie/${idCategorie}/${linkName}`} className="about item-category"><h2>{titleCategorie}</h2></Link>}
        {content && this.createPosts()}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataLastPost: state.postsByCategorie.dataLastPost,
  dataListCategories: state.categories.dataListCategories,
})

const mapDispatchToProps = (dispatch) => ({
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
  GET_LAST_POSTS_BY_CATEGORY_ASYNC: (category) => dispatch({ type: 'GET_LAST_POSTS_BY_CATEGORY_ASYNC', category }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LastPostsByCategorie)
