import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactHtmlParser from 'react-html-parser'
import LastPosts from '../lastPosts'

class SinglePage extends PureComponent {
  static propTypes = {
    dataArticle: PropTypes.array,
    dataMedia: PropTypes.array,
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    GET_MEDIA_ASYNC: PropTypes.func.isRequired,
    id: PropTypes.number,
    onClick: PropTypes.func,
    pageHelp: PropTypes.bool,
    noTitle: PropTypes.bool,
  }

  static defaultProps = {
    dataArticle: null,
    dataMedia: null,
    id: null,
    onClick: null,
    pageHelp: false,
    noTitle: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      title: null,
      content: null,
      featuredMediaCurrent: null,
      linkImg: null,
      article_2249: false,
    }
  }

  componentDidMount() {
    const {
      dataArticle,
      GET_ARTICLE_ASYNC,
      id,
    } = this.props

    // DETECT IF ARTICLE == article_2249
    if (id === 2249) {
      this.setState({
        article_2249: true,
      })
    }

    // verify if dataArticle has already been called
    let exist = false;
    if (dataArticle.length === 0) {
      if (_.find(dataArticle, { id: id }) === undefined) {
        GET_ARTICLE_ASYNC(id);
      }
    } else {
      _.map(dataArticle, (elem) => {
        const idArticle = elem.id;
        const idWanted = id;
        if (idArticle === idWanted) {
          exist = true;
          this.createArticle();
        }
      })

      if (!exist && _.find(dataArticle, { id: id }) === undefined) {
        GET_ARTICLE_ASYNC(id);
      }
    }
  }

  componentDidUpdate() {
    const {
      title,
      linkImg,
    } = this.state

    const {
      pageHelp,
    } = this.props

    if (!linkImg) {
      this.getImg()
    }
    if (!title) {
      this.createArticle()
    }
    // FOR PAGE HELP
    if (pageHelp) {
      const help = document.querySelector('#help');
      if (help !== null) {
        help.scrollIntoView();
      }
    }
  }

  getImg = () => {
    const {
      dataMedia,
    } = this.props;

    const {
      featuredMediaCurrent,
    } = this.state

    _.map(dataMedia, (currentDataMedia) => {
      if (currentDataMedia.data.id === featuredMediaCurrent) {
        this.setState({
          linkImg: currentDataMedia.data.guid.rendered,
        })
      }
    })
  }

  createArticle = () => {
    const {
      dataArticle,
      id,
      GET_MEDIA_ASYNC,
      dataMedia,
    } = this.props;

    _.map(dataArticle, (currentData) => {
      if (currentData.data.id === id) {
        if (currentData.data.featured_media !== 0 && _.find(dataMedia, { id: id }) === undefined) {
          GET_MEDIA_ASYNC(currentData.data.featured_media)
        }

        this.setState({ featuredMediaCurrent: currentData.data.featured_media })

        const titleArticle = currentData.data.title.rendered;
        const contentArticle = currentData.data.content.rendered;
        this.setState({
          title: titleArticle,
          content: contentArticle,
        })
      }
    })
  }

  render() {
    const {
      title,
      content,
      linkImg,
      article_2249,
    } = this.state;

    const {
      id,
      noTitle,
    } = this.props;

    const regExScript = /<script\b[^>]*>([\s\S]*?)<\/script>/gm
    const scripts = regExScript.exec(content)
  
    const js = () => (
      <script type="text/javascript">
        {_.map(scripts, (s) => {
          const j = s.replace(/<script\b[^>]*>/gm).replace(/<\/script>/gm)
          eval(j || null)
        })}
      </script>
    )
    const { onClick } = this.props
    const isFaq = () => (id === 17 ? 'faq' : '')
    return (
      <>
        <div id={`article_${id}`} onClick={onClick} className={`article ${isFaq()}`} style={linkImg ? { backgroundImage: `url(${linkImg})` } : null}>
          <div>
            {noTitle || id === 2202 ? null : <h2>{ReactHtmlParser(title)}</h2>}
            {/* {id === 2202 && !noTitle ? <h1>{ReactHtmlParser(title)}</h1> : null} */}
            <div>
              {ReactHtmlParser(content)}
              {article_2249 && <LastPosts type="home" />}
            </div>
          </div>
        </div>
        {js()}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  dataArticle: state.singlePage.dataArticle,
  dataMedia: state.home.dataMedia,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),  
  GET_MEDIA_ASYNC: (featuredMedia) => dispatch({ type: 'GET_MEDIA_ASYNC', featuredMedia }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SinglePage)
