import { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types'

class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props
    return children;
  }
}
export default withRouter(ScrollToTop)
