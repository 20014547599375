import React, { PureComponent } from 'react'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import logo from '../../assets/images/logo.svg'

class Menu extends PureComponent {
  static propTypes = {
    setStateDrawer: PropTypes.func.isRequired,
    GET_MENU_ASYNC: PropTypes.func.isRequired,
    dataMenu: PropTypes.object,
    location: PropTypes.object.isRequired,
  }

  static defaultProps = {
    dataMenu: null,
  }

  call = null

  componentDidMount() {
    const {
      setStateDrawer,
    } = this.props;

    setStateDrawer(false)
    this.getMenu()

    this.verifTabComponentdidUpdate()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.verifTabComponentdidUpdate()
      this.getMenu()
    }
  }

  getMenu = () => {
    const {
      location,
      GET_MENU_ASYNC,
    } = this.props
    const getMenu = location.pathname.indexOf('inscription') >= 0 ? 'LandingPage' : 'MenuPrincipal'
    clearTimeout(this.call)
    this.call = setTimeout(() => {
      GET_MENU_ASYNC(getMenu)
    }, 100)
  }

  loginRedirect = () => {
    window.location = 'https://secure25.monentrepriz.com/CELIFI'
  }

  registerRedirect = () => {
    window.location = 'https://secure25.monentrepriz.com/CELIFI?open_mode=1'
  }

  buttonRedirect(url) {
    window.location = url
  }

  verifTabComponentdidUpdate() {
    const listTabMenu = document.querySelectorAll('.tab-menu')
    const url = location.href

    _.map(listTabMenu, (elem) => {
      elem.classList.remove('tab-menu-selected')
      
      const hrefTab = elem.href
      if (hrefTab === url) {
        elem.classList.add('tab-menu-selected')
      }
      const urlAccueil = `${location.origin}/`
      if (elem.innerHTML === 'Accueil' && url === urlAccueil) {
        elem.classList.add('tab-menu-selected')
      }
    })
  }

  verifTabSelected(e) {
    const listTabMenu = document.querySelectorAll('.tab-menu')

    _.map(listTabMenu, (elem) => {
      elem.classList.remove('tab-menu-selected')
    })
    if (e !== undefined) {
      if (e.target.className === 'tab-logo') {
        _.map(listTabMenu, (elem) => {
          if (elem.innerHTML === 'Accueil') {
            elem.classList.add('tab-menu-selected')
          }
        })
      } else {
        e.target.classList.add('tab-menu-selected')
      }
    }
  }

  createMenu() {
    const {
      dataMenu,
    } = this.props

    const list = []

    _.map(dataMenu.items, (elem) => {
      // if (elem.title === 'Tarifs') {
      //   list.push(<li key={elem.ID}><a onClick={() => window.location = '#article_2239'} type="button" className="tab-menu">{elem.title}</a></li>)
      // } else 
      if (elem.title === 'Inscription gratuite') {
        list.push(<li key={elem.ID}><button onClick={() => window.location = '#inscription'} type="button" className="button secondary full">{elem.title}</button></li>)
      } else if (elem.url === 'https://secure25.monentrepriz.com/CELIFI' || elem.url === 'https://secure25.monentrepriz.coi.im/CELIFI') {
        // button se connecter
        list.push(<li key={elem.ID}><button onClick={() => this.buttonRedirect(elem.url)} type="button" className="button secondary">{elem.title}</button></li>)
      } else if (elem.url === 'https://secure25.monentrepriz.com/CELIFI?open_mode=1' || elem.url === 'https://secure25.monentrepriz.coi.im/CELIFI?open_mode=1') {
        // button se connecter
        list.push(<li key={elem.ID}><button onClick={() => this.buttonRedirect(elem.url)} type="button" className="button secondary full">{elem.title}</button></li>)
      } else {
        // element du menu
        const removeLinkPreprod = 'https://monentrepriz.coi.im/';
        const removeLinkProd = 'https://www.monentrepriz.com/';
        const link = elem.url.replace(removeLinkPreprod, '').replace(removeLinkProd, '').replace('/', '')
        if (elem.url === 'https://monentrepriz.coi.im/inscription/' || elem.url === 'https://www.monentrepriz.com/inscription/') {
          list.push(<li key={elem.ID}><Link to={`${link}`} onClick={(e) => this.verifTabSelected(e)} className="tab-menu buton-menu"><button type="button" className="button secondary full">{elem.title}</button></Link></li>)
        } else {
          list.push(<li key={elem.ID}><Link to={`/${link}`} onClick={(e) => this.verifTabSelected(e)} className="tab-menu">{elem.title}</Link></li>)
        }
      }
    })

    return (
      <ul>
        {list}
      </ul>
    )
  }

  render() {
    const {
      setStateDrawer,
      dataMenu,
    } = this.props;

    return (
      <menu id="main-menu">
        <div id="nav-hamburger" onClick={() => setStateDrawer(true)}> </div>
        <div id="logo" className="logo"><Link to="/" onClick={(e) => this.verifTabSelected(e)} className="home"><img className="tab-logo" src={logo} /></Link></div>

        {dataMenu && this.createMenu()}
      </menu>
    )
  }
}

const mapStateToProps = (state) => ({
  stateDrawer: state.menu.stateDrawer,
  dataMenu: state.menu.dataMenu,
})

const mapDispatchToProps = (dispatch) => ({
  setStateDrawer: (boolDrawer) => dispatch({ type: 'STATE_DRAWER', boolDrawer }),
  GET_MENU_ASYNC: (path) => dispatch({ type: 'GET_MENU_ASYNC', path }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu))
