import {
  ADD_CATEGORIE,
} from './actions'

const initialState = {
  dataListCategories: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORIE:
      return {
        ...state,
        dataListCategories: [...state.dataListCategories, { id: action.dataCategorie.id, data: action.dataCategorie }],
      }
    default:
      return state
  }
}
