/* eslint-disable arrow-body-style */
import React from 'react'
import SinglePage from '../singlePage'

const newsSlider = () => {
  return (
    <div className="newsSlider">
      <h4>L'ACTU</h4>
      <SinglePage id={3414} />
    </div>
  )
}

export default newsSlider
