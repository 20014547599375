import {
  M_PAGES,
} from './actions'

const initialState = {
  token: null,
  member: null,
  refs: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case M_PAGES:
      return {
        ...state,
        pages: action.pages,
      }
    default:
      return state
  }
}
