import {
  GET_FOOTER,
} from './actions'

const initialState = {
  dataFooter: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FOOTER:
      return {
        ...state,
        dataFooter: action.dataFooter,
      }
    default:
      return state
  }
}
