const http = window.location.href.indexOf('https') >= 0 ? 'https' : 'http'
export const isLocal = window.location.href.indexOf('localhost') >= 0
export const isPreprod = window.location.href.indexOf('coi.im') >= 0

const baseURL = isLocal || isPreprod
  ? `${http}://monentrepriz.coi.im/wp-json`
  : `${http}://www.monentrepriz.com/wp-json`

// const baseURL = `${http}://www.monentrepriz.com/wp-json`

const urlWp = `${baseURL}/wp/v2/`;
const urlMenus = `${baseURL}/menus/v1/menus/`;

export default {
  baseURL,
  urlWp,
  urlMenus,
  isLocal,
  isPreprod,
}
