import React from 'react'
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import {
  ConnectedRouter,
  routerMiddleware,
} from 'connected-react-router'
import createRootReducer from './reducers'
import rootSaga from './sagas'
import Routes from './routes'
import ScrollToTop from '../scrollToTop'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
    ),
    applyMiddleware(sagaMiddleware),
    applyMiddleware(thunk),
  ),
)

sagaMiddleware.run(rootSaga)

const Router = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>
)

export default Router
