import axios from 'axios'
import config from '../../../config'

const getHead = () => axios({
  method: 'get',
  baseURL: `${config.baseURL}/wp/v2/head`,
})

const getFooter = () => axios({
  method: 'get',
  baseURL: `${config.baseURL}/wp/v2/footer`,
})

export default {
  getHead,
  getFooter,
}
