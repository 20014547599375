import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import api from '../api/reducers'
import home from '../pages/home/api/reducers'
import menu from '../components/menu/api/reducers'
import header from '../components/header/api/reducers'
import lastPosts from '../components/lastPosts/api/reducers'
import singlePage from '../components/singlePage/api/reducers'
import singlePost from '../components/singlePost/api/reducers'
import categories from '../components/categories/api/reducers'
import allPosts from '../pages/allPosts/api/reducers'
import postsByCategorie from '../pages/postsByCategorie/api/reducers'
import search from '../components/menuCategories/api/reducers'
import footer from '../components/footer/api/reducers'
import pagination from '../components/pagination/api/reducers'
import preview from '../pages/preview/api/reducers'
import contact from '../pages/contact/api/reducers'

const AppReducer = (history) => combineReducers({
  router: connectRouter(history),
  header,
  api,
  home,
  menu,
  lastPosts,
  singlePage,
  singlePost,
  categories,
  allPosts,
  postsByCategorie,
  search,
  footer,
  pagination,
  preview,
  contact,
})

export default AppReducer
