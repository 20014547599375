import {
  GET_FORM,
  SEND_MAIL,
} from './actions'

const initialState = {
  formProperties: null,
  status: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_FORM:
      return {
        ...state,
        formProperties: action.formProperties,
      }
    case SEND_MAIL:
      return {
        ...state,
        status: action.status,
      }
    default:
      return state
  }
}
