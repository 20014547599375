export const S_GET_HEAD = 'S_GET_HEAD'
export const S_GET_FOOTER = 'S_GET_FOOTER'

export const getHeadResult = (data) => ({
  type: S_GET_HEAD,
  head: data,
})

export const getFooterResult = (data) => ({
  type: S_GET_FOOTER,
  footer: data,
})
