import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  isLocal,
  isPreprod,
} from '../../config'

import Dashboard from '../../assets/images/landing/dashboard.png'
import Icon1 from '../../assets/images/landing/piggy-icon.svg'
import Icon2 from '../../assets/images/landing/agenda-icon.svg'
import Icon3 from '../../assets/images/landing/bill-icon.svg'
import Section2 from '../../assets/images/landing/section2.png'
import Section3 from '../../assets/images/landing/dashboard-mobile.png'
import Logo1 from '../../assets/images/landing/logo1.png'
import Logo2 from '../../assets/images/landing/logo2.png'

class Landing extends PureComponent {
  iframeUrl = isLocal || isPreprod
    ? 'https://testprod25.monentrepriz.com/CELIFI_PREPROD?open_mode=9'
    : 'https://secure25.monentrepriz.com/CELIFI?open_mode=9'

  render() {
    return (
      <div id="Container" className="landingPage">
        <section className="section0">
          <div className="containerInner">
            <div className="part partLeft">
              <h1>
                Auto-entrepreneurs : Gagnez du temps dans la gestion de votre entreprise !
              </h1>
              <p>Développez votre activité <strong>en toute sérénité</strong> en utilisant MonEntrepriz, l’application web qui simplifie la vie des auto-entrepreneurs dans la gestion de leur quotidien.</p>
              <a className="button secondary" href="#inscription">Testez gratuitement</a>
            </div>
            <div className="part image">
              <img src={Dashboard} />
            </div>
          </div>
        </section>

        <section className="section1">
          <div className="containerInner">
            <h2>
              <strong>Simplifiez avant tout</strong> la gestion de votre activité 
            </h2>
            <p>
              MonEntrepriz, <strong>le coup de pouce qui vous manque</strong> et qui vous accompagne au quotidien grâce à un <strong>coach virtuel</strong> ! Consacrez plus de temps à votre métier et à vos clients grâce à MonEntrepriz, l’application qui vous permet de gérer simplement votre environnement administratif. MonEntrepriz est une solution sécurisée et adaptée aux besoins des Micro-Entrepreneurs et comporte tous les outils nécessaires pour une activité réussie.
            </p>
            
            <div className="icons">
              <div>
                <img src={Icon1} />
                <h3>Gérer votre budget</h3>
                <p>Libérez-vous des contraintes liées à la gestion administrative. Évaluez en un coup d'œil votre budget.</p>
              </div>
              <div>
                <img src={Icon2} />
                <h3>Organisez votre temps</h3>
                <p>Parce que nous savons que votre temps est précieux, libérez-vous des contraintes liées à la gestion administrative.</p>
              </div>
              <div>
                <img src={Icon3} />
                <h3>Créez facilement des devis et factures</h3>
                <p>MonEntrepriz est conforme aux évolutions réglementaires et vous permettra de générer vos pièces administratives en un clic.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section2">
          <div className="containerInner">
            <div className="part partLeft">
              <h2>Un accompagnement personnalisé</h2>
              <p>Ne perdez plus de temps, gagnez en productivité grâce à MonEntrepriz. L’application est ergonomique et simple d’utilisation, elle vous offre la possibilité de vous concentrer sur ce qui est essentiel : votre cœur d’activité !</p>
              <ul>
                <li>Optez pour un accompagnement <strong>sur-mesure grâce à Lucy</strong>, un business coach qui vous accompagne et vous conseille au quotidien, </li>
                <li>MonEntrepriz, l’appli simple avant tout des <strong>auto-entrepreneurs</strong> qui ne veulent pas rester micro toute une vie,</li>
                <li>100 % digitale et accessible, l’application est tout support, disponible en temps réel et partout, utilisez votre ordinateur ou téléchargez MonEntrepriz sur les stores IOS et Android, </li>
                <li>Choisissez une solution sécurisée : parce que vos données sont importantes, notre application est hébergée sur un serveur sécurisé <strong>en France.</strong></li>
              </ul>
            </div>
            <div className="part image">
              <img src={Section2} />
            </div>
          </div>
        </section>

        <section className="section3">
          <div className="containerInner">
            <div className="part image">
              <img src={Section3} />
            </div>
            <div className="part">
              <h2><strong>Améliore considérablement</strong> la gestion quotidienne et votre développement </h2>
              <p>Créez des devis professionnels, des factures et des bons de commande personnalisés à l’image de votre société en un temps record. MonEntrepriz se charge pour vous des calculs et de l’envoie par mail des pièces administratives. :</p>
              <ul>
                <li>Générez vos pièces administratives en quelques clics,</li>
                <li>Assurez le suivi de vos prospects, clients et fournisseurs par le biais de fiches personnalisées et enregistrées en ligne.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="section4">
          <div className="containerInner">
            <h2>Ils ont choisi d’utiliser MonEntrepriz</h2>
            <div className="cards">
              <div className="card">
                <p>« Je recherchais ce type de logiciel : suite à une période d’essai gratuite pour valider son utilisation, j’ai ensuite souscrit à un abonnement payant car il est complet et pas trop cher. »</p>
                <div>
                  <h4>Florian Le Corre</h4>
                  <span>Fabriquant d’aménagement et équipement amovible de loisirs pour véhicules</span>
                </div>
                <img src={Logo1} />
              </div>
              <div className="card">
                <p>« L’application est très facile à compléter, à comprendre, et très pratique, tout est visible. Je recommande MonEntrepriz parce que c’est vraiment l’idéal pour un micro entrepreneur, simple mais complet. »</p>
                <div>
                  <h4>Julie Carton</h4>
                  <span>Créatrice d’accessoires pour réduire les déchets et d’accessoires pour enfants</span>
                </div>
                <img src={Logo2} />
              </div>
            </div>
          </div>
        </section>

        <section id="inscription" className="section5">
          <div className="containerInner">
            <div className="part">
              <h2><strong>N’ayez plus peur de prendre les bonnes décisions</strong>, essayez gratuitement Monentrepriz pendant 3 mois  </h2>
              <p>
                <strong>Qui sommes nous ?</strong><br />
                <br />
                « Le Groupe Fiméco Walter France se place au 17ème rang dans le classement des cabinets d’expertise comptable en France avec un ADN centré autour des entrepreneurs et de leurs projets. Implanté depuis 50 ans sur la région Grand Sud Ouest, au- delà de l’expertise comptable, l’audit et le conseil, le Groupe fait évoluer sa palette de services afin de répondre aux plus près des attentes de ses clients entrepreneurs. ».
              </p>
            </div>
            <iframe title="Inscription" src={this.iframeUrl} />
          </div>
          <div className="margin" />
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataArticle: state.home.dataArticle,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
