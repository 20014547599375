import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  getPost,
} from './actions'

const defaultError = {
  type: 'TOASTER',
  item: {
    body: 'Une erreur est survenue',
    validity: 5,
    type: 'error',
  },
}

function* getPostAsync(params) {
  try {
    const { id } = params
    const { data } = yield call(me.getPostById, id)
    yield put(getPost(data))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

export function* fetchResult() {
  yield takeEvery('GET_POST_ASYNC', getPostAsync)
}

export default fetchResult
