import {
  STATE_DRAWER,
  GET_MENU,
} from './actions'

const initialState = {
  stateDrawer: false,
  dataMenu: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STATE_DRAWER:
      return {
        ...state,
        stateDrawer: action.boolDrawer,
      }
    case GET_MENU:
      return {
        ...state,
        dataMenu: action.dataMenu,
      }
    default:
      return state
  }
}
