import React, { PureComponent } from 'react'
import inIcone from '../../assets/images/inColor.svg'
import fbColor from '../../assets/images/fbColor.svg'
import youTubeColor from '../../assets/images/youTubeColor.svg'

class NewsLetter extends PureComponent {
  render() {
    return (
      <div id="news-letter">
        <div id="icone-news-letter"> </div>
        <div className="email-news">
          <p>Recevez toutes les nouveautés sur mon MonEntrepriz et les informations les plus récentes sur le statut de micro-entrepreneur.</p>
          <div>
            <iframe title="NewsLetter" className="mj-w-res-iframe" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://app.mailjet.com/widget/iframe/3tEX/kqZ" height="40" width="100%" />
            <script type="text/javascript" src="https://app.mailjet.com/statics/js/iframeResizer.min.js" />
          </div>
        </div>
        <div className="social-network-news">
          <p>Nous sommes présents sur les réseaux sociaux, n'hésitez pas à nous suivre</p>
          <div>
            <a className="icon-network" href="https://www.linkedin.com/company/monentrepriz/?originalSubdomain=fr" target="_blank" rel="noopener noreferrer"><img src={inIcone} alt="logo linkedin" /></a>
            <a className="icon-network" href="https://fr-fr.facebook.com/monentrepriz/" target="_blank" rel="noopener noreferrer"><img src={fbColor} alt="logo facebook" /></a>
            <a className="icon-network" href="https://www.youtube.com/channel/UCr_2De-KoZ8cLNHFSDImIBg" target="_blank" rel="noopener noreferrer"><img src={youTubeColor} alt="logo youtube" /></a>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsLetter
