import {
  SEARCH_ITEM,
  SET_KEYWORDS,
} from './actions'

const initialState = {
  search: [],
  keywords: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_ITEM:
      return {
        ...state,
        search: action.search,
      }
    case SET_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords,
      }
    default:
      return state
  }
}
