import {
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import me from '.'
import {
  setPreviewPost,
  setPreviewPage,
} from './actions'

const defaultError = {
  type: 'TOASTER',
  item: {
    body: 'Une erreur est survenue',
    validity: 5,
    type: 'error',
  },
}

function* getDraftPostAsync(params) {
  try {
    const { p } = params.params
    const { data } = yield call(me.getPreviewPost, p)

    yield put(setPreviewPost(data))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

function* getDraftPageAsync(params) {
  try {
    const { page_id } = params.params
    console.log('SAGA draft Page')
    console.log('params =>', params)
    const { data } = yield call(me.getPreviewPage, page_id)
    console.log('saga data => ', data)

    yield put(setPreviewPage(data))
  } catch ({ response }) {
    yield put(defaultError)
  }
}

function* getPreviewAsync(params) {
  try {
    const { preview_id } = params.params
    try {
      const { data } = yield call(me.getPreviewPost, params.params.preview_id)

      yield put(setPreviewPost(data))
    } catch ({ response }) {
      yield put(defaultError)
    }

    try {
      const { data } = yield call(me.getPreviewPage, preview_id)

      yield put(setPreviewPage(data))
    } catch ({ response }) {
      yield put(defaultError)
    }
  } catch ({ response }) {
    yield put(defaultError)
  }
}

export function* fetchResult() {
  yield takeEvery('GET_DRAFT_POST', getDraftPostAsync)
  yield takeEvery('GET_DRAFT_PAGE', getDraftPageAsync)
  yield takeEvery('GET_PREVIEW', getPreviewAsync)
}

export default fetchResult
