import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import SinglePage from '../../components/singlePage'
import NewsLetter from '../../components/newsLetter'

class Faq extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    GET_ARTICLE_ASYNC: PropTypes.func.isRequired,
    dataSinglePage: PropTypes.array,
  }

  static defaultProps = {
    match: {},
    dataSinglePage: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      help: false,
      pageFaq: 17,
    }
    this.element = React.createRef()
  }

  componentDidMount() {
    const {
      match,
    } = this.props

    const { GET_ARTICLE_ASYNC } = this.props
    GET_ARTICLE_ASYNC(17)
    // document.title = 'MonEntrepriz - FAQ'

    if (match.params.help === 'help') {
      this.setState({
        help: true,
      })
    }
    setTimeout(this.toggleFaq, 300)
  }

  componentDidUpdate() {
    this.toggleFaq()
    const { dataSinglePage } = this.props
    const current = _.find(dataSinglePage, (page) => page.id === 17)
    if (current) {
      const { yoast_meta } = current.data
      if (yoast_meta) {
        document.title = ReactHtmlParser(_.find(yoast_meta, { property: 'og:title' })?.content || 'MonEntrepriz')
        _.map(yoast_meta, ({ name, property, content }) => {
          let meta = null
          if (name) meta = document.querySelector(`meta[name="${name}"]`)
          if (property) meta = document.querySelector(`meta[property="${property}"]`)
          if (meta) meta.setAttribute('content', content);
        })
      }
    }
  }

  toggleFaq = () => {
    if (this.element.current) {
      const lis = this.element.current.querySelectorAll('.article.faq li')
      _.map(lis, (li) => {
        const a = li.querySelector('a')
        const content = li.querySelector('.faq-content')
        a.onclick = () => {
          li.classList.toggle('content-visible')
          content.classList.toggle('content-visible')
        }
      })
    }
  }

  render() {
    const {
      pageFaq,
      help,
    } = this.state
    return (
      <div id="Container" ref={this.element}>
        <NewsLetter />
        <SinglePage id={pageFaq} pageHelp={help} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dataMedia: state.home.dataMedia,
  dataSinglePage: state.singlePage.dataArticle,
})

const mapDispatchToProps = (dispatch) => ({
  GET_ARTICLE_ASYNC: (id) => dispatch({ type: 'GET_ARTICLE_ASYNC', id }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Faq)
