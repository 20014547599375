import axios from 'axios'
import config from '../../../config'

const getPostById = (id) => (id ? axios({
  method: 'get',
  baseURL: `${config.urlWp}posts/${id}`,
}) : null)

export default {
  getPostById,
}
